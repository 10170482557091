<template>
  <div>
    <a-form-model
      ref="ruleForm"
      :model="form"
    >
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-model-item
            :label="$t('utils.price')"
            prop="priceMin"
          >
            <a-input
              v-model="form.priceMin"
              size="large"
              addon-after="Coin"
              :placeholder="$t('redeem.minimum')"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item class="mt-6" prop="priceMax">
            <a-input
              v-model="form.priceMax"
              size="large"
              addon-after="Coin"
              :placeholder="$t('redeem.maximum')"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-model-item
            :label="$t('redeem.stock')"
            prop="stockMin"
          >
            <a-input
              v-model="form.stockMin"
              size="large"
              :placeholder="$t('redeem.minimum')"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item class="mt-6" prop="stockMax">
            <a-input
              v-model="form.stockMax"
              size="large"
              :placeholder="$t('redeem.maximum')"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <div class="drawer-footer">
      <a-button
        class="mr-3"
        type="primary"
        size="large"
        ghost
        @click="onClose"
      >
        <a-icon type="delete" />
        {{ $t('redeem.reset_filter') }}
      </a-button>
      <a-button type="primary" size="large" @click="onClose">
        <a-icon type="filter" />
        {{ $t('redeem.apply') }}
      </a-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RedeemProductDrawerDetail',
}
</script>

<script setup>
import { reactive } from 'vue'

const form = reactive({
  priceMin: null,
  priceMax: null,
  stockMin: null,
  stockMax: null,
})
</script>

<style lang="scss" scoped>
.mt-6 {
  margin-top: 2.65rem;
}
.drawer-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
  z-index: 1;
}
</style>
