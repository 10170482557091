<template>
  <div>
    <div class="d-flex">
      <h1 class="h3">
        {{ $t('redeem.redeem_product') }}
      </h1>
      <div class="ml-auto d-flex">
        <a-button
          v-if="!isWareshouse"
          class="create-button d-flex align-items-center h-35px p-3"
          style="font-size: 12px"
          @click="handleModal(true)"
        >
          Buat Produk
        </a-button>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-6">
        <a-input-search
          v-model.trim="keyword"
          size="large"
          :placeholder="$t('redeem.search')"
          :loading="isLoading"
          :disabled="isLoading"
          allow-clear
          @search="onSearch"
        >
          <a-button slot="enterButton" class="bg-primary-blue text-white">
            {{ $t('utils.search') }} 
            <a-icon type="search" />
          </a-button>
        </a-input-search>
      </div>
      <div class="col-md-2">
        &nbsp;
      </div>
      <div class="col-md-2">
        <a-button
          size="large"
          class="w-100 b-blue-2 text-blue-2"
          :disabled="isLoading"
          @click="onShowDrawer('filter')"
        >
          <a-icon type="filter" />
          Filter
        </a-button>
      </div>
      <div class="col-md-2">
        <a-select size="large" default-value="created" class="w-100 ml-auto text-capitalize select-antd-default">
          <a-select-opt-group>
            <span slot="label">Sort by</span>
            <a-select-option value="alphabetical">
              Alphabetical
            </a-select-option>
            <a-select-option value="created">
              Date Created
            </a-select-option>
            <a-select-option value="modified">
              Last Modified
            </a-select-option>
          </a-select-opt-group>
          <a-select-opt-group label="Order">
            <a-select-option value="oldest">
              Oldest First
            </a-select-option>
            <a-select-option value="newest">
              Newest First
            </a-select-option>
          </a-select-opt-group>
        </a-select>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <a-tabs
          v-if="viewTab"
          v-model="activeKey"
          class="mb-2 pca-tab-store"
          :disabled="isLoading"
          @change="onTabChange"
        >
          <a-tab-pane
            v-for="tab in tabs"
            :key="tab"
            :tab="`${$t('store_list_page.' + tab)}`"
          />
        </a-tabs>
      </div>
    </div>
    <!-- <div class="mt-3 bg-white p-3">
      <div class="d-flex flex-wrap align-items-center">
        <div>
          <a-tag class="p-1 fs-14" closable>
            Harga
          </a-tag>
          <a-tag class="p-1 fs-14" closable>
            Stok
          </a-tag>
        </div>
        <div class="ml-auto">
          <a-button
            type="primary"
            :disabled="isLoading"
            ghost
            @click="onClearFilter"
          >
            <a-icon type="delete" />
            {{ $t('redeem.reset_filter') }}
          </a-button>
        </div>
      </div>
    </div> -->
    <div class="mt-3">
      <a-table
        :data-source="redeemList"
        :row-key="record => record.id"
        :columns="columns"
        :loading="isLoading"
        :pagination="false"
        :scroll="{ y: 500 }"
      >
        <template slot="name" slot-scope="text, record">
          <span class="d-flex text-blue-2 cursor-pointer" @click="onShowDrawer('filter')">
            <div>
              <img 
                :src="record.showimg_url ? record.showimg_url : $defaultImage + '?tr=w-20,h-20,cm-pad_resize,bg-FFF'"
                :alt="text"
                class="product-image mr-3"
                draggable="false"
                width="60"
                height="60"
              />
            </div>
            <div style="align-self: center; font-weight: bold">
              <a-tooltip placement="topRight">
                <template #title>
                  <span>{{ text }}</span>
                </template>
                {{ textElipsis(text) }}
              </a-tooltip>
            </div>
          </span>
        </template>
        <template slot="catalogs" slot-scope="text, record">
          <div>
            {{ record.catalogs?.length }}
          </div>
        </template>
        <template slot="range_price" slot-scope="text, record">
          <div>
            <a-tooltip placement="topRight">
              <template #title>
                <span>{{ price(record) }}</span>
              </template>
              {{ textElipsis(price(record)) }}
            </a-tooltip>
          </div>
        </template>
        <template slot="status" slot-scope="text, record">
          <a-tag
            v-if="record.status === 'NOT_ACTIVE'"
            :color="colors[record.status]"
          >
            {{ $t(`store_list_page.${record.status?.toLowerCase()}`) }}
          </a-tag>
          <a-tag v-else :color="colors[text]">
            {{ $t(`store_list_page.${text?.toLowerCase()}`) }}
          </a-tag>
        </template>
        <template slot="action" slot-scope="record">
          <div class="text-right">
            <a-dropdown>
              <a @click.prevent="">
                <a-icon type="more" />
              </a>
              <template #overlay>
                <a-menu>
                  <a-menu-item
                    key="2"
                    class="py-2"
                    @click="$router.push({
                      name: 'redeem-channel.redeem-product-channel-edit',
                      query: {
                        ...$route.query,
                        business_id: $store.state.user.businessList[0].business_id,
                        id: record.id,
                        edit: true
                      },
                    })"
                  >
                    Lihat Detail
                  </a-menu-item>
                  <a-menu-item v-if="!isWareshouse" key="3" class="py-2" @click="showModalUpdate(record, true)">
                    {{ record.product_status === 'ACTIVE' || record.status === 'ACTIVE' ? $t('store_list_page.deactivate_product') : $t('store_list_page.activate_product') }}
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </template>
      </a-table>
    </div>
    <div class="mt-3 text-right">
      <a-pagination
        v-model="params.page"
        :page-size="params.limit"
        :page-size-options="sizeOptions"
        :total="params.total"
        :show-total="(total, range) => $t('utils.pagination_show_total', { perpage: `${range[0]} - ${range[1]}`, total })"
        :disabled="isLoading"
        show-size-changer
        @change="onPageChange"
        @showSizeChange="onShowSizeChange"
      />
    </div>

    <a-drawer
      title="Filter"
      :width="500"
      :visible="showDrawer"
      :body-style="{ paddingBottom: '80px' }"
      @close="onCloseDrawer"
    >
      <RedeemProductDrawerDetail
        v-if="drawerType === 'detail'"
        :data_detail="data_detail"
      />
      <RedeemProductDrawer v-else />
    </a-drawer>

    <a-modal
      :visible="visibleUpdate"
      :footer="false"
      :closable="false"
    >
      <!-- <div class="text-center">
        <Question class="mx-3 mb-3 mt-3" />
      </div> -->
      <h3 class="text-center mb-3">
        Ubah Status Product
      </h3>
      <div class="text-center mt-4">
        <span style="color: #6C757D"> Apakah Anda yakin ingin {{ rowData?.product_status === 'ACTIVE' ? 'menonaktifkan' : 'mengaktifkan' }} produk ini ? </span>
      </div>
      <div class="text-center mt-5">
        <a-button class="inbound__button mx-2 ml-auto" @click="showModalUpdate({}, false)">
          Batalkan
        </a-button>
        <a-button type="primary" :loading="isLoading" @click="changeProductStatus">
          Ya
        </a-button>
      </div>
    </a-modal>

    <ModalCreate
      :show-modal="showModal"
      :loading="isLoading"
      :data-product-master="productMasterList"
      :params="paramsMaster"
      :size-option="sizeOptions"
      pages="redeem"
      @handleModal="handleModal"
      @onPageChangeMaster="onPageChangeMaster"
      @onShowSizeChangeMaster="onShowSizeChangeMaster"
      @onSearchMaster="onSearchMaster"
    />
  </div>
</template>
<script setup>
  import { reactive, ref, onMounted, getCurrentInstance, computed, watch } from 'vue'
  import { columnsRedeem } from '@/data/columns'
  import RedeemProductDrawerDetail from '@/components/Drawer/Redeem/RedeemProductDrawer'
  import RedeemProductDrawer from '@/components/Drawer/Redeem/RedeemProductDrawer/filter.vue'
  import { GETPRODUCT } from '@/api/channels/distributor'
  import { getProductMasterList } from '@/api/product'
  import { channelEventProcess, channelSyncProcess, updateProductChannelStatus } from '@/api/channels/index'
  import ModalCreate from '@/views/redeem-channel/ModalCreate.vue'
  import route from '@/router'
  
  const { $route, $router, $store, $notification } = getCurrentInstance().proxy.$root
  const keyword = ref('')
  const drawerType = ref('filter')
  const isLoading = ref(false)
  const total = ref(0)
  const showDrawer = ref(false)
  const showModal = ref(false)
  const columns = columnsRedeem
  const redeemList = ref([])
  const productMasterList = ref([])
  const sizeOptions = ref(['10', '20', '30', '40', '50'])
  const params = reactive({
    page: 1,
    limit: 10,
    total: 3,
    status: '',
  })
  const paramsMaster = reactive({
    page: 1,
    limit: 10,
    total: 3,
  })
  const colors = {
    ACTIVE: 'blue',
    UNCONFIRMED: 'orange',
    INACTIVE: 'red',
    NOT_ACTIVE: 'red',
  }
  const data_detail = ref({})
  const search_query = ref('')
  const search_product = ref('')
  const activeKey = ref('all')
  const visibleUpdate = ref(false)
  const rowData = ref({})
  const requestId = ref('')
  const eventProcess = ref(false)

  const isWareshouse = computed(() => $store.state.user.restriction_base === 'Warehouse')

  const viewTab = computed(() => {
    const query = route.app._route.query
      if (
        query &&
        query.workspace_id.includes('KINO')
      ) {
        return true
      }
      if (
        query &&
        !['distributor', 'store'].includes(query.channel_code)
      ) {
        return true
      }
      return false
  })

  const tabs = computed(() => {
    if (
      route.app._route.query &&
      route.app._route.query.workspace_id.includes('KINO')
      ) {
        return ['all', 'active', 'notActive']
      }
      return ['all', 'unconfirmed', 'confirmed']
  })

  const listChannels = computed(() => {
    const channel = $store.state.business.listChannels.find(item => item.sales_channel.code === 'distributor_redeem')
    return channel?.id || 0
  })

  watch(eventProcess, (newValue) => {
    if (newValue === true) {
      setTimeout(() => {
        if (visibleUpdate.value) {
          getEventProcess(requestId.value)
        } else {
          getSyncProcess(requestId.value)
        }
      }, 2000)
    }
  })

  watch(listChannels, () => {
    fetchProduct()
    fetchProductsMaster()
  })

  onMounted(() => {
    fetchProduct()
    fetchProductsMaster()
  })
  
  const fetchProduct = async() => {
    if(!listChannels.value) return

    isLoading.value = true
    const businessId = $route.query['business_id'] || (await getBusinessId())
    try {
      const response = await GETPRODUCT(
        { 
          business_id: businessId,
          channel: $route.query.channel_code,
          channelId: listChannels.value,
          params: {
            ...params,
            search_query: search_query.value,
            sort: 'created_at',
            order: 'desc',
          },
        },
      )
      redeemList.value = response.data
      params.total = response.total_row
      isLoading.value = false
    } catch(error){
      isLoading.value = false
      console.log(error)
    }
  }

  const fetchProductsMaster = async() => {
    if(!listChannels.value) return

    isLoading.value = true
    const businessId = $route.query['business_id'] || (await getBusinessId())
    try {
      const response = await getProductMasterList({ 
        business_id: businessId,
        params: {
          ...paramsMaster,
          ...$route.query,
          q: search_product.value || undefined,
        },
      })
      productMasterList.value = response.data.data
      total.value = response.data.total_row
      paramsMaster.total = response.data.total_row
      isLoading.value = false
    } catch(error){
      isLoading.value = false
      console.log(error)
    }
  }

  const getBusinessId = async () => {
    return await $store.state.user.businessList[0].business_id
  }

  const handleModal = (param) => {
    showModal.value = param
  }

  const textElipsis = (text) => {
      if (text.length > 30) {
        return text.substring(0, 30) + '...'
      } else {
        return text
      }
    }

  const onSearch = (e) => {
    params.page = 1
    search_query.value = e
    fetchProduct()
  }

  const onSearchMaster = (e) => {
    params.page = 1
    search_product.value = e
    fetchProductsMaster()
  }
  
  const onPageChange = (page) => {
    params.page = page
    fetchProduct()
  }

  const onPageChangeMaster = (page) => {
    paramsMaster.page = page
    fetchProductsMaster()
  }
  
  const onShowSizeChange = (current, size) => {
    params.page = 1
    params.limit = size
    fetchProduct()
  }

  const onShowSizeChangeMaster = (size) => {
    paramsMaster.page = 1
    paramsMaster.limit = size
    fetchProductsMaster()
  }

  // const onClearFilter = () => {
  //   console.log('clear filter')
  // }

  const onShowDrawer = (type, record) => {
    drawerType.value = type
    showDrawer.value = true
    if (type == 'detail') {
      data_detail.value = record
    }
  }

  const onCloseDrawer = () => {
    showDrawer.value = false
  }

  const parseNum = (val) => {
    return val ? 'IDR ' + val.toLocaleString($store.state?.settings?.locale || 'id-ID') : ''
  }

  const price = (record) => {
    if (record && record.range_price) {
      const koin = parseNum(record.range_price.lowest_price)
      return koin.replace('IDR', '').trim() + ' Koin'
    } else {
      return '-'
    }
  }

  const onTabChange = (tab) => {
    params.page = 1
    switch (tab) {
      case 'unconfirmed':
        params.status = 'ACTIVE'
        break
      case 'confirmed':
        params.status = 'ACTIVE'
        break
      case 'active':
        params.status = 'ACTIVE'
        break
      case 'notActive':
        params.status = 'NOT_ACTIVE'
        break
      default:
        params.status = ''
        break
    }
    fetchProduct()
  }

  const showModalUpdate = (row, param) => {
    rowData.value = row
    visibleUpdate.value = param
  }

  const changeProductStatus = async () => {
    isLoading.value = true
    const channelCode = 'distributor'
    const channelId = listChannels.value
    let payload = {
        product_ids: [rowData.value.product_id],
        product_status: rowData.value.product_status === 'ACTIVE' ? 'NON_ACTIVE' : 'ACTIVE',
      }
    const statusChange = rowData.value.status === 'ACTIVE' ? 'deactivate' : 'activate'
    payload = {
      product_id: rowData.value.id,
    }
    try {
      const response = await updateProductChannelStatus({
        method: 'put',
        business_id: $route.query.business_id || $store.state.user.businessList[0].business_id,
        channel_code: channelCode,
        channel_id: channelId,
        status: statusChange,
        data: payload,
      })
      if (response.data.data.status === "ON_PROGRESS") {
        requestId.value = response.data.data.request_id
        getEventProcess(response.data.data.request_id)
      } else if (response.data.message === "Success") {
        isLoading.value = false
        $notification.success({
          message: 'Status Produk Berhasil Diupdate',
        })
        visibleUpdate.value = false
        fetchProduct()
      }
    } catch (e) {
      console.log({e})
      if (e) {
        $notification.error({
          message: e.message || 'Gagal Mengubah Status Product, Silahkan Coba Lagi.',
        })
        isLoading.value = false
        showModalUpdate({}, false)
      }
    }
  }

  const getEventProcess = async (request_id) => {
    eventProcess.value = false
    const channelCode = 'distributor'
    const channelId = listChannels.value
    try {
      const response = await channelEventProcess({
        business_id: $route.query.business_id || $store.state.user.businessList[0].business_id,
        channel_code: channelCode,
        channel_id: channelId,
        request_id,
      })
      if (response.data.status === "FAILED") {
        eventProcess.value = false
        isLoading.value = false
        $notification.error({
          message: response.data.message || 'Gagal Diupdate, Coba Lagi!',
        })
      } if (response.data.status === "ON_PROGRESS") {
        eventProcess.value = true
        requestId.value = request_id
      } else if (response.data.status === "FINISHED") {
        eventProcess.value = false
        requestId.value = ''
        isLoading.value = false
        $notification.success({
          message: 'Status Produk Berhasil Diupdate',
        })
        visibleUpdate.value = false
        fetchProduct()
      }
    } catch (e) {
      console.log({e})
      if (e.response.status === 404) {
        eventProcess.value = false
        isLoading.value = false
        $notification.error({
          message: 'Gagal Sync Product, Coba Lagi.',
        })
      }
    }
  }

  const getSyncProcess = async (request_id) => {
    eventProcess.value = false
    const channelCode = 'distributor'
    const channelId = listChannels.value
    try {
      const response = await channelSyncProcess({
        business_id: $route.query.business_id || $store.state.user.businessList[0].business_id,
        channel_code: channelCode,
        channel_id: channelId,
        request_id,
      })
      if (response.data.data.status === "ON_PROGRESS") {
        eventProcess.value = true
        requestId.value = request_id
      } else if (response.data.data.status === "FINISHED") {
        eventProcess.value = false
        requestId.value = ''
        isLoading.value = false
        $notification.success({
          message: 'Produk Berhasil Disinkronisasi',
        })
        fetchProduct()
      } else if (response.data.data.status === "FAILED") {
        eventProcess.value = false
        isLoading.value = false
        $notification.error({
          message: response.data.data.message,
        })
      }
    } catch (e) {
      console.log({e})
      if (e.response.status === 404) {
        eventProcess.value = false
        isLoading.value = false
        $notification.error({
          message: e.response.data.message || e.response.data.error || e.messagge,
        })
      }
    }
  }
</script>
<style lang="scss">
.ant-btn {
  font-weight: 500 !important;
}
.ant-table-row {
  background-color: #fff !important;
}
.ant-drawer-title {
  font-size: 24px !important;
  font-weight: bold !important;
}
.select-antd-default.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px !important;
}
.b-blue-2 {
  border-color: #2196F3 !important;
}
.fs-14 {
  font-size: 14px !important;
}
.cursor-pointer {
  cursor: pointer;
}
.create-button {
  color: #fff !important;
  background: #ff0854 !important;
}
.pca-tab-store {
  .ant-tabs-bar {
    // background-color: #fff;
    margin-bottom: 0;
    border-bottom-color: transparent;
  }
  .ant-tabs-nav .ant-tabs-tab:hover,
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #ff0a54;
  }
  .ant-tabs-ink-bar {
    background-color: #ff0a54;
  }
}
.pca-tab-status {
  .ant-tabs-bar {
    // background-color: #fff;
    margin-bottom: 0;
    border-bottom-color: transparent;
  }
  .ant-tabs-tab {
    padding: 0 25px !important;
    border-radius: 20px !important;
  }
  .ant-tabs-tab-active {
    background: #ff0a54 !important;
    color: #fff !important;
  }
}
</style>